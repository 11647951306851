nav {
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, #2b00aa, rgb(3, 0, 54));
  height: 6vw;
  color: white;
  /* background-image: url(https://res.cloudinary.com/aditya-college-of-engineering-technology/image/upload/v1653376671/a8b2807ab8f7eb54a8ab0b9adad8a247_hdxffr.jpg);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat; */
}
.kapat_logo {
  font-family: "Roboto", sans-serif;
  margin-left: 8vw;
  color: white;
}

.kapat_logo_subtitle {
  font-size: 0.1rem;

  margin-left: 100px;
}
.kapat_logo {
  font-size: 3vw;
  font-weight: 500;
  margin-bottom: -12px;
}

.nav-right {
  height: 100%;
}

.nav-menu {
  font-size: 1.04vw;
}

ul {
  list-style-type: none;
  height: 100%;
}

li {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1vw;
}

.menu-bar {
  display: none;
}
.menu-bar-icon {
  position: absolute;
  right: 3vw;
  top: 2.6vw;
  color: white;
  font-size: 2vh;

  cursor: pointer;
}
.mob-menu {
  z-index: 8;
  display: block;
  width: 100vw;
  margin: 0;
  position: absolute;
  top: 5.3vw;
  left: 0;
  right: 0;
  background-color: #1516459a;
  text-align: center;
}

@media only screen and (max-width: 1020px) {
  .nav-right {
    display: none;
  }

  .menu-bar {
    display: block;
  }

  nav {
    height: 7vw;
  }
  .nav-left p {
    font-size: 3.5vw;
  }
  .mob-menu {
    top: 7vw;
  }
}

@media only screen and (min-width: 1021px) {
  .mob-menu {
    display: none;
  }
}
